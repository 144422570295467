export default {
  dashboard: 'Главная',
  products: 'Управление товарами',
  catalog: {
    store: 'Каталог магазина',
    rozetka: 'Каталог Rozetka'
  },
  xml: 'Работа с XML',
  xmls: {
    import: 'Импорт XML',
    export: 'Эспорт XML',
    view: 'Просмотр XML'
  },
  excel: 'Работа с Excel',
  excels: {
    import: 'Импорт Excel',
    export: 'Эспорт Excel',
    params: 'Работа с параметрами'
  },
  office: 'Кабинет продавца',
  instruction: 'Инструкция',
  regulations: 'Правила',
  theme: 'Оформление',
  permissions: 'Разрешения',
  permission: {
    admin: 'Админ',
    editor: 'Редактор',
    visitor: 'Посетитель',
  },
  errors: 'Ошибки',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: 'Графики',
  chart: {
    keyboardChart: 'Гистограмма',
    lineChart: 'Линейный',
    mixChart: 'Смешанный',
  },
  clipboard: 'Буфер обмена',
  externalLink: 'Внешняя ссылка',
};
