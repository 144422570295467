<template>
  <v-app-bar
    dark
    app
    :dense="toolbarDense"
  >
    <v-app-bar-nav-icon @click.stop="toggleNavbar">
      <v-icon>{{ toggleNavbarIcon }}</v-icon>
    </v-app-bar-nav-icon>
    <v-spacer />
<!--    <v-text-field-->
<!--        label=" "-->
<!--        :placeholder="$t('fields.search')"-->
<!--        hide-details-->
<!--        clearable-->
<!--        class="col-3"-->
<!--        dense-->
<!--        v-model="value"-->
<!--        :append-outer-icon="value ? 'mdi-magnify' : ''"-->
<!--        @click:append-outer="search()"-->
<!--        v-on:keyup.enter="search()"-->
<!--    ></v-text-field>-->
    <full-screen />
    <notification />
    <localization />
    <profile />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FullScreen from '../widget/AppFullScreen.vue';
import Localization from '../widget/AppLocalization.vue';
import Notification from '../widget/AppNotification.vue';
import Profile from '../widget/AppProfile.vue';

export default {
  name: 'TheLayoutToolbar',
  components: {
    FullScreen,
    Localization,
    Notification,
    Profile,
  },
  data: () => ({
    value: ''
  }),
  computed: {
    ...mapGetters([
      'toolbarDense',
      'navbarShow',
    ]),
    toggleNavbarIcon() {
      return this.navbarShow ? 'mdi-format-indent-decrease' : 'mdi-format-indent-increase';
    },
  },
  methods: {
    search(){
      this.$store.commit("SET_VALUE_SEARCH", this.value)
      if(this.$route.path !== '/search') {
        this.$router.push('/search')
      }
    },
    toggleNavbar() {
      this.$store.dispatch('NavbarToggle');
    },
  },
};
</script>
<style>
.v-text-field{
  padding-bottom: 0;
}
.v-input__append-outer{
  margin-bottom: 0!important;
  margin-top: 0!important;
  padding-top: 2px!important;
}
</style>
