

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { settings } from '@/config';
import vuetify from '@/locale/vuetify';
import en from './en_US';
import ru from './ru_RU';
import uk from './uk_UK'

Vue.use(VueI18n);

const messages = {
  ru: {
    ...ru,
    $vuetify: vuetify.ru,
  },
  en: {
    ...en,
    $vuetify: vuetify.en,
  },
  uk: {
    ...uk,
    $vuetify: vuetify.uk,
  },
};

/**
 * Available locales
 */
export const locales = [
  {
    title: 'Русский',
    locale: 'ru',
    abbr: 'RUS',
  },
  {
    title: 'English',
    locale: 'en',
    abbr: 'ENG',
  },
  {
    title: 'Український',
    locale: 'uk',
    abbr: 'UKR',
  },
];

/**
 * VueI18n instance
 */
const i18n = new VueI18n({
  // set locale options: en_US | ru_RU
  locale: settings.locale,
  // set locale messages
  messages,
});

/**
 * Set locale to new value at Vue and Vuex.
 *
 * @param {Boolean} newLocale themeDark new value
 */
export async function setLocale(locale) {
  if (i18n.locale !== locale) {
    console.log(`[Locale] Set to "${locale}"`);
    i18n.locale = locale || settings.locale;
  } else {
    console.warn(`[Locale] "${locale}" is current`);
  }
}

export default i18n;
