<template>
  <div>
    <v-btn
        color="green"
        text
        @click="sendEvent('all_valid')"
    >
      Все ОК
    </v-btn>
    <v-btn
        color="red"
        text
        @click="sendEvent('all_invalid')"
    >
      Все выключить
    </v-btn>
    <div class="fl-autocomplete">
      <v-autocomplete
          v-model="selectValidators"
          :items="$store.getters['contentPreparation/getErrorsList']"
          item-text="name"
          item-value="id"
          chips
          clearable
          deletable-chips
          multiple
          placeholder="Метки"
          small-chips
          @blur="sendEvent('error_list')"
      >
      </v-autocomplete>
    </div>

  </div>
</template>

<script>
// import item from "@/modules/planner/routes/item";

export default {
  props: ["item"],
  name: "imageValidationControl",
  data() {
    return {
      selectValidators: [],
      controls: [
        // "Лишние элементы",
        // "Ватермарк",
        // "Текст",
        // "Ссылка",
        // "Не соответствует характеристикам",
        // "Маленький размер",
        // "Плохое качество",
        // "Фон",
        // "Нет фото для первой картинки",
        // "Разбить на отдельные товары",
        // "Нет бренда",
      ]
    }
  },
  methods: {
    sendEvent(action) {
      if (action === 'error_list') {
        this.$emit("send", {action, data: this.selectValidators, id: this.item.id})
      } else {
        this.$emit("send", {action, id: this.item.id})
        this.selectValidators = []
      }
    }
  }
}
</script>

<style scoped>
.fl-autocomplete {
  margin: 0;
  padding: 0 2px;
}
</style>
