import Layout from '@/views/layout/TheLayout.vue';

const excelRouter = {
    path: '/excel',
    component: Layout,
    name: 'Excel',
    redirect: 'noredirect',
    meta: {
        title: 'route.excel',
        icon: 'mdi-file-excel-outline',
    },
    children: [
        {
            path: 'import',
            component: () => import('../../views/components/Excel/importExc'),
            name: 'Import Excel',
            meta: {
                title: 'route.excels.import',
                icon: 'mdi-file-import-outline',
                noCache: true,
            },
        },
        {
            path: 'export',
            component: () => import('../../views/components/Excel/exportExc'),
            name: 'Export Excel',
            meta: {
                title: 'route.excels.export',
                icon: 'mdi-file-export-outline',
                noCache: true,
            },
        },
        {
            path: 'params',
            component: () => import('../../views/components/Excel/ParamsWork'),
            name: 'Working with parameters',
            meta: {
                title: 'route.excels.params',
                icon: 'mdi-clipboard-list-outline',
                noCache: true,
            },
        },
    ],
};

export default excelRouter;
