import Layout from '@/views/layout/TheLayout.vue';

const productsRouter = {
  path: '/',
  component: Layout,
  name: 'Product Management',
  redirect: 'noredirect',
  meta: {
    title: 'route.products',
    icon: 'mdi-table',
  },
  children: [
    {
      path: 'catalog_store',
      component: () => import('../../views/components/Products/AllProducts'),
      name: 'Store catalog',
      meta: {
        title: 'route.catalog.store',
        icon: 'mdi-package-variant-closed',
        noCache: true,
      },
    },
    {
      path: 'catalog_rozetka',
      component: () => import('../../views/components/Products/Categories'),
      name: 'Rozetka Catalog',
      meta: {
        title: 'route.catalog.rozetka',
        icon: 'mdi-power-socket-de',
        noCache: true,
      },
    },
    {
      path: 'product/:category/:id',
      component: () => import('../../views/components/Products/ProductCard'),
      name: 'Product',
      hidden: true,
    },
    {
      path: 'category/:id',
      component: () => import('../../views/components/Products/CategoryCard'),
      name: 'Category',
      hidden: true,
    },
    {
      path: 'search',
      component: () => import('../../views/components/Products/ResultSearch'),
      name: 'Search',
      hidden: true,
    },
    {
      path: 'products/category/:id',
      component: () => import('../../views/components/Products/Products'),
      name: 'Products',
      hidden: true,
    },
  ],
};

export default productsRouter;
