import axios from 'axios';

const products = {

    state: {
        loading: false,
        dashboard: {},
        products: [],
        current_product: {},
        result_search: [],
        value_search: '',
    },
    mutations: {
        SET_LOADING(state, bool) {
            state.loading = bool
        },
        SET_PRODS(state, prods) {
            state.products = prods;
        },
        SET_CURRENT_PROD(state, prod) {
            state.current_product = prod;
        },
        SET_RESULT_SEARCH(state, result) {
            state.result_search = result
        },
        SET_VALUE_SEARCH(state, value) {
            state.value_search = value
        },
        SET_DASHBOARD(state, value) {
            state.dashboard = value
        },
    },
    actions: {
        async initDashboard({state, commit}) {
            commit("SET_LOADING", true)
            const response = await axios.get('dashboard/stats')
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_DASHBOARD', response.data);
            } else {
                commit('SET_DASHBOARD', []);
            }
            commit("SET_LOADING", false)
        },
        async searchProducts({state, commit}, page = 1) {
            if (state.value_search) {
                commit("SET_LOADING", true)
                let params = {
                    'page': page,
                    'filter[active]': true,
                    'filter[name][like]': state.value_search,
                    'filter[code][like][or]': state.value_search,
                }
                const response = await axios.get(`item`, {
                    params: params
                })
                    .then(response => response)
                    .catch(err => err);
                if (response) {
                    commit('SET_RESULT_SEARCH', response.data);
                } else {
                    commit('SET_RESULT_SEARCH', []);
                }
                commit("SET_LOADING", false)
            }
        },
        async initProducts({state, commit}, params) {
            commit("SET_LOADING", true)
            const response = await axios.get(`item`, {
                params: params
            })
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_PRODS', response.data);
            } else {
                commit('SET_PRODS', []);
            }
            commit("SET_LOADING", false)
        },
        async currentProduct({state, commit}, data) {
            commit("SET_LOADING", true)
            const product = await axios.get(`item/${data.id}`, {params: {expand: data.params}})
                .then(response => response)
                .catch(err => err);
            if (product) {
                commit('SET_CURRENT_PROD', product.data);
            } else {
                commit('SET_CURRENT_PROD', {});
            }
            commit("SET_LOADING", false)
        },
        async saveProduct({state, commit}, data) {
            commit("SET_LOADING", true)
            const response = await axios.post(`item`, {'CatalogCategory': data.category,'CatalogItem':data.product})
                .then(response => response)
                .catch(err => err);
            console.log(response)
            commit("SET_LOADING", false)
            return response.data
        },
        async updateProduct({state, commit}, data) {
            commit("SET_LOADING", true)
            const response = await axios.put(`item/${data.id}`, {'CatalogItem':data.product})
                .then(response => response)
                .catch(err => err);
            console.log(response)
            commit("SET_LOADING", false)
        },
        async deleteProduct({state, commit}, id) {
            commit("SET_LOADING", true)
            console.log(id)
            const response = await axios.delete(`item/${id}`)
                .then(response => response)
                .catch(err => err);
            commit("SET_LOADING", false)
            return response.data
        },
    },
    getters: {
        getProducts: ({products}) => products,
        getCurrentProduct: ({current_product}) => current_product,
        getLoading: ({loading}) => loading,
        getResultSearch: ({result_search}) => result_search,
        getValueSearch: ({value_search}) => value_search,
        getDashboard: ({dashboard}) => dashboard,
    }
};
export default products;
