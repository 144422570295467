<template>
  <div>
    <div class="row-fl">
      <div  class="img-bx">
        <v-img
            aspect-ratio="1"  :src="'img/prod.jpg'"
            :width="width"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
    <v-btn outlined class="btn-all" v-if="checkCount" text x-small @click="checker = !checker">
      <span v-if="!checker">Показать еще...</span>
      <span v-else>Скрыть...</span>
    </v-btn>
  </div>

</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      reqiured: true
    },
    count: {
      default: () => 3
    },
    width: {
      default: () => 200
    }
  },
  data() {
    return {
      checker: false,

    }
  },
  computed: {
    checkCount() {
      return this.items.length > this.count;

    },
    getItems() {
      if (this.items.length < this.count) {
        return this.items
      }

      if (this.checker) {
        return this.items
      } else {
        return this.items.slice(0, this.count)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.row-fl {
  display: flex;
  flex-wrap: wrap;
}

.img-bx {
  margin: 3px;
}

.btn-all {
  :hover {
    color: #3596f3;
  }
}
</style>
