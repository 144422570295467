<template>
  <div class="pl-4 bolean dt-td" functional>
    <a :href="item" class="href" target="_blank">
      <v-icon>mdi-login-variant</v-icon>
    </a>
  </div>
</template>

<script>
export default {
  props: ["item"]
};
</script>
<style scoped>
.href {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

</style>
