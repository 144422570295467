export default {
  dashboard: 'Dashboard',
  products: 'Product Management',
  catalog: {
    store: 'Store catalog',
    rozetka: 'Rozetka Catalog'
  },
  xml: 'Work XML',
  xmls: {
    import: 'Import XML',
    export: 'Export XML'
  },
  excel: 'Work Excel',
  excels: {
    import: 'Import Excel',
    export: 'Export Excel',
    params: 'Working with parameters'
  },
  office: "Seller's office",
  instruction: 'Instruction',
  regulations: 'Rules',
  documentation: 'Documentation',
  guide: 'Guide',
  roadmap: 'Roadmap',
  theme: 'Theme',
  // pagePermission: 'Page Permission',
  // rolePermission: 'Role Permission',
  permission: {
    admin: 'Admin',
    editor: 'Editor',
    visitor: 'Visitor',
  },
  components: 'Components',
  component: {
    avatarUpload: 'Avatar Upload',
    backToTop: 'Back To Top',
    countTo: 'Count To',
    dragKanban: 'Drag Kanban',
    dropzone: 'Dropzone',
    jsonEditor: 'Json Editor',
    markdown: 'Markdown',
    splitPane: 'Split Pane',
    sticky: 'Sticky',
    tinymce: 'Tinymce',
  },

  errors: 'Errors',
  errorPages: {
    page301: '301',
    page401: '401',
    page403: '403',
    page404: '404',
    page500: '500',
  },
  charts: 'Charts',
  chart: {
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
  },
  nested: {
    nested: 'Nested',
    nested1: 'Level 1',
    nested2: 'Level 2',
    nested3: 'Level 3',
  },
  clipboard: 'Clipboard',
  externalLink: 'External Link',
};
