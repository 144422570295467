<template>
  <div class="pl-4 bolean dt-td" functional>
    {{toDate(item)}}
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {
    toDate(val) {
      return new Date(val);
    }
  }
};
</script>
<style scoped>
</style>
