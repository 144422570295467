import Layout from '@/views/layout/TheLayout.vue';

const xmlRouter = {
    path: '/xml',
    component: Layout,
    name: 'XML',
    redirect: 'noredirect',
    meta: {
        title: 'route.xml',
        icon: 'mdi-xml',
    },
    children: [
        {
            path: 'import',
            component: () => import('../../views/components/XML/importXML'),
            name: 'Import XML',
            meta: {
                title: 'route.xmls.import',
                icon: 'mdi-file-import-outline',
                noCache: true,
            },
        },
        {
            path: 'export',
            component: () => import('../../views/components/XML/exportXML'),
            name: 'Export XML',
            meta: {
                title: 'route.xmls.export',
                icon: 'mdi-file-export-outline',
                noCache: true,
            },
        },
        {
            path: 'view',
            component: () => import('../../views/components/XML/viewXML'),
            name: 'View XML',
            meta: {
                title: 'route.xmls.view',
                icon: 'mdi-file-eye-outline',
                noCache: true,
            },
        },
    ],
};

export default xmlRouter;
