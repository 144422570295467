<template>
  <div class="row-fl pa-2">
    <div class="col-exp"
        v-for="header in expandRows"
        :style="[flBase(header.width), fixed(header.fixed, header.width)]"
    >
      <params
          :items="item[header.value]"
          :title="header.title"
          v-if="header.template === 'params'"
      />
      <image-pl
          :items="item[header.value]"
          v-if="header.template === 'images'"
          :count=header.count
      />
      <description
          :items="item[header.value]"
          v-if="header.template === 'description'"
          :title="header.title"
          :spoiler="header.spoiler"
      />
      <param-rozetka
          :items="item[header.value]"
          v-if="header.template === 'paramRozetka'"
          :title="header.title"
          @send="test"
      />
      <btn-moderation
          v-if="header.template === 'btnModeration'"
          :items="item[header.value]"
          @send="emit"
      />

    </div>
  </div>
</template>

<script>
import params from './templates/params'
import imagePl from './templates/img'
import description from './templates/description'
import paramRozetka from "./templates/paramRozetka";
import btnModeration from "./templates/btnModeration";


export default {
  name: "expandTemp",
  data() {
    return {
      gridAreaArr: [
        "grid-a",
        "grid-b",
        "grid-c",
        "grid-d",
        "grid-e",
        "grid-f",
        "grid-g",
      ],
    }
  },
  components: {
    params,
    imagePl,
    description,
    paramRozetka,
    btnModeration,
  },
  methods: {
    emit(e) {
      let obj = {
        productid: this.item.id,
        ...e
      }
      console.log(obj)
      this.$emit('send', obj)
    },

    fixed(f, w) {
      if (f) {
        return {
          minWidth: w + 'px'
        }
      }
    },
    flBase(w) {
      if (w) {
        return {
          flexBasis: w + 'px'
        }
      }
    },
  },
  computed: {

    areas() {
      const gridArea = ["a", "b", "c", "d", "e", "f", "g"]
      let area = ""
      this.expandRows.forEach((_, idx) => {
        area += gridArea[idx] + " "
      })
      console.log("area", area)
      if (area.length) {
        // return {gridTemplateAreas: area}
        return {'grid-template-areas': area, color: "red"}
      }
      return area
    }
  },
  props: {
    expandRows: {
      type: Array,
      default: () => ([])
    },
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>
.row-fl {
  display: flex;
  flex-wrap: nowrap;
  border-right: 1px solid #efefee;
  border-left: 1px solid #efefee;
  border-bottom: 1px solid #efefee;
}

.col-exp {
  margin-right: 7px;
}
</style>
