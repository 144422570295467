<template>
  <div class="dt-td td-select" functional>
    <div
      v-if="activeItem.color"
      class="mini-circle mr-1"
      :style="{ background: activeItem.color }"
    ></div>

    <v-select
      dense
      class="small-select"
      v-model="data"
      solo
      :items="headerItem.list"
      item-text="name"
      item-value="id"
      @change="test"
      hide-details
    ></v-select>
  </div>
</template>

<script>
export default {
  props: ["item", "headerItem", "obj"],
  data() {
    return {
      data: null,
    };
  },
  methods: {
    test() {
      //console.log(this.data);
      if (this.headerItem.func) {
        this.headerItem.func(this.data, this.obj);
      }
    },
  },
  watch: {
    item() {
      this.data = this.item;
    },
  },
  computed: {
    activeItem() {
      let f = this.headerItem.list.find((o) => o.id === this.data);
      return f ? f : {};
    },
  },
  created() {
    this.data = this.item;
  },
};
</script>
