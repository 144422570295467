<template>
  <div>
    <div>
      <h3 class="tit">
        <v-btn class="href" text @click="sendActions('info')">{{ item.name }}</v-btn>
        <br>
        <span class="ven-tit ml-5">VENDOR:</span> <span class="ven-val">{{ item.vendor }}</span></h3>
    </div>
    <div class="image-box">
      <v-col
          v-for="(item, i) in images"
          :key="item.id"
          class="image-item" cols="6"
          md="3"
          @mouseout="showPreview(false)" @mouseover="showPreview(true, item.orgLink)">
        <v-card
            class="image-card"
            outlined
        >
          <!--        :class="{ active: selections.includes(item.id) }"-->
          <!--        @click="select(item)"-->
          <v-img
              :lazy-src="
              item.orgLink.includes('http')
                ? item.orgLink
                : baseUrl + item.orgLink
            "
              :src="
              item.orgLink.includes('http')
                ? item.orgLink
                : baseUrl + item.orgLink
            "
              contain
              height="150px"
          />

          <v-card-actions>
            <div :title="`(id ${item.id})`" class="caption">
              <!--            {{ item.width }}x{{ item.height }}-->
              4440x2045
            </div>

            <v-spacer></v-spacer>
            <v-btn
                color="green"
                icon
                small
                title="Ок"
                @click="sendActions(1, item.id, item)"
            >
              <v-icon small>mdi-check-bold</v-icon>
            </v-btn>
            <v-btn
                color="orange"
                icon
                small
                title="Pause"
                @click="sendActions(0, item.id, item)"
            >
              <svg style="width:16px;height:16px" viewBox="0 0 24 24">
                <path
                    d="M15,16H13V8H15M11,16H9V8H11M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    fill="currentColor"/>
              </svg>
            </v-btn>
            <v-btn
                color="red"
                icon
                small
                title="Stop"
                @click="sendActions(-1, item.id, item)"
            >
              <v-icon small>mdi-image-off-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </div>

    <div v-if="show && prevLink" class="prev-image">
      <v-img
          :src="
              prevLink.includes('http')
                ? prevLink
                : baseUrl + prevLink
            "
          class="white--text align-end"
          contain
          width="650px"
      />
    </div>

  </div>
</template>

<script>
// import item from "@/modules/planner/routes/item";

export default {
  props: ["item"],
  name: "imageValidationField",
  data() {
    return {
      prevLink: '',
      show: false
    }
  },
  computed: {
    images() {
      return this.item.images
    }
  },
  methods: {
    showPreview(status, link = "") {
      if (status) {
        this.prevLink = link
        this.show = true
        return
      }
      this.prevLink = ''
      this.show = false
    },
    sendActions(action, i = 0, item = undefined) {
      if (i) {
        this.$emit("send", {action, id: this.item.id, image: i})
      } else {
        this.$emit("send", {action, id: this.item.id})
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.ven-tit {
  font-size: 13px;
}

.ven-val {
  font-size: 14px;
  font-weight: 600;
  color: #458fff;
}

.image-box {
  display: flex;
  flex-wrap: wrap;
}

.image-item {
  min-width: 200px;

  :hover {
    transition: 0.03s;
    transform: scale(1.03);
  }

}

.prev-image {
  position: fixed;
  right: 30px;
  top: 110px;
  z-index: 2;
  border: 1px solid #458fff;
  transition: 0.3s;
}


.tit {
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 500;
  margin-right: 50px;
}

.href {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.href:hover {
  color: #0056b3;
  text-decoration: underline;
}
</style>
