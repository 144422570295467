<template>
  <div>
    <h3 v-if="title">{{ title }}:</h3>
    <div :class="{'m-h': !checker}" class="description" v-html="getItems"></div>
    <!--    <p class="text-a"-->
    <!--    >{{ getItems }}</p>-->
    <v-btn class="btn-all" v-if="checkCount" text x-small @click="checker = !checker">
      <span v-if="!checker">Показать еще...</span>
      <span v-else>Скрыть...</span>
    </v-btn>
  </div>
</template>

<script>

export default {
  props: ["items", "title", "spoiler"],
  data() {
    return {
      checker: false,
      count: 300
    }
  },
  created() {
    if(this.spoiler === true){
      this.checker = true
    }
  },
  computed: {
    checkCount() {
      return this.items.length > this.count;

    },
    getItems() {
      let pattern = /<iframe.*?\/iframe>/gm;
      // let pattern = /src="([^"]+)"/
      let items = this.items
          .replace(/<img[^>]*>/g, "<span style='color: #b85252'>{REPLACE-IMAGE}</span>")
          .replace(pattern, "<span style='color: #b85252'>{REPLACE-VIDEO}</span>")

      if (items.length < this.count) {
        return items
      }
      // console.log(items)
      if (this.checker) {
        return items
      } else {
        return items.slice(0, this.count) + "..."
      }
    }
  }
};
</script>
<style scoped lang="scss">
.btn-all {
  :hover {
    color: #3596f3;
  }
}

.m-h {
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-a {
  font-size: 13px;
  word-break: break-all;
}
</style>
