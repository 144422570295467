<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="subtitle-2 mb-4">{{$t('table.table_settings')}}</v-card-title>

      <v-card-text>
        <div class="data-table" style="color: #000">
          <div class="dt-header pl-1">
            <div class="pl-8"></div>
            <div class="td-th caption d-flex justify-space-between" style="width: 47%;">
              <span title="username" class="th-text">{{$t('fields.name2')}}</span>
            </div>
            <div class="td-th caption d-flex justify-space-between" style="width: 47%;">
              <span title="ID" class="th-text">{{$t('table.key')}}</span>
            </div>
            <!-- <div class="td-th caption d-flex justify-space-between" style="width: 30%;">
              <span title="ID" class="th-text">Ширина колонки</span>
            </div> -->
          </div>

          <div class="wrap-td-boby  wr-sc">
            <div class="td-boby">
              <draggable :list="headers" ghost-class="ghost">
                <div class="dt-row" v-for="(item, k) in headers" :key="k">
                  <div class="d-flex dt-row-inner align-center pl-1">
                    <v-checkbox
                      class="table-mini-checkbox"
                      dense
                      hide-details
                      v-model="item.active"
                    ></v-checkbox>
                    <div class="dt-cell" style="width: 47%;">
                      <div>
                        <div class="dt-td">{{item.text}}</div>
                      </div>
                    </div>
                    <div class="dt-cell" style="width: 47%;">
                      <div>
                        <div class="dt-td">{{item.value}}</div>
                      </div>
                    </div>
                    <!-- <div class="dt-cell" style="width: 30%;">
                      <div>
                        <div class="dt-td">
                          <input class="dt-input" v-model="item.width" type="number" />
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="dialog = false">{{$t('btns.close')}}</v-btn>
        <v-btn color="yellow darken-1" text @click="clearHeaders">{{$t('btns.clear')}}</v-btn>

        <v-btn color="green darken-1" v-if="$parent.tabId" text @click="save">{{$t('btns.save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: {
    headers: {
      type: Array
    }
  },
  data() {
    return {
      dialog: false
    };
  },

  methods: {
    clearHeaders() {
      this.$parent.removeState("headers");
    },
    save() {
      this.$parent.setState(this.headers, "headers");
      this.dialog = false;
    }
  }
};
</script>
