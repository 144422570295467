<template>
  <div v-if="item"
       class="dt-td cursor-pointer href cat-title"
       @click="$emit('send', {data, meta, id: data.id})"
  >
    <span v-if="meta === 1">
      <a :href="`${$route.path}/${data.id}`" @click.prevent>
        <v-icon color="green">mdi-bullseye</v-icon>
        {{ item }}
      </a>
  </span>
    <span v-else>
      <a :href="`${$route.path}/${data.id}`" @click.prevent>
        <v-icon color="red">mdi-bullseye-arrow</v-icon>
        {{ item }}
      </a>
    </span>

  </div>
</template>

<script>
export default {
  name: "paramsLink",
  props: ["item", "meta", "data"],

}
</script>

<style lang="scss" scoped>
.href {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  background-color: transparent;

  a {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    background-color: transparent;
  }
}

.href:hover {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  transition: 0.03s;
  //text-decoration: underline;
}
</style>
