export default {
    isfolder: {
        type: Boolean,
        default: false
    },
    limtCols: {
        type: Number,
        default: null
    },
    defaultIcon: {
        type: String,
        default: "mdi-package-variant-closed"
    },
    keyRow: {
        type: String,
        default: "id"
    },
    loading: {
        type: Boolean,
        default: false
    },
    actions: {
        type: Array,
        default: null
    },
    items: {
        type: Array,
        required: true
    },
    height: {
        type: String,
        default: "calc(100vh - 180px)"
    },
    headers: {
        type: Array,
        required: true
    },
    tabId: {
        type: String,
        default: null
    },
    activeId: {
        default: null
    },
    parent: {
        type: String,
        default: "parent"
    },
    selectable: {
        type: String,
        default: null
    },
    severEvent: {
        type: Boolean,
        default: false
    },
    selected: {
        type: Array,
        default: () => ([])
    },
    map: {
        type: Boolean,
        default: true
    },
    sortTable: {
        type: Object
    },
    showExpand: {
        type: Boolean,
        default: false
    },
    expandRows: {
        type: Array,
        default: () => ([])
    },
    activeExpandRows: {
        type: Array,
        default: () => ([])
    },
    showPagin: {
        type: Boolean,
        default: () => false
    },
    typePaginateServer: {
        type: Boolean,
        default: () => false
    },
    pramsPag: {
        type:Object,
        default: () => ({ size: 0, totalElements: 0, totalPages: 0 })
    },
    page: {
        type: Number,
        default: () => 1
    }
};
