<template>
    <div>
    <v-btn
            icon
            dark
            @click="dialogDelete = !dialogDelete"
            :title="$t('btns.delete')"
            color="red"
    >
        <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-dialog
            v-model="dialogDelete"
            width="500"
    >
        <v-card class="pa-3">
            <p class="text-center"><b>{{$t('btns.delete')}}?</b></p>
            <span>"{{item.param}}"</span>
            <div class="d-flex justify-center">
                <v-btn text color="red" @click="$emit('send', { key: $event, action: 'btnParamsEdit', func: 'delete', id: item.param_id})">{{$t('btns.delete')}}</v-btn>
                <v-btn text color="orange" @click="dialogDelete=false">{{$t('btns.cancel')}}</v-btn>
            </div>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "btnParamsEdit",
        props: ["item"],
        data() {
            return {
                dialogDelete: false,
            }
        }
    }
</script>

<style scoped>

</style>
