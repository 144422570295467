import route from './route';


export default {
  route,
  toolbar: {
    appname: 'Приложение',
    settings: 'Настройки',
    profile: 'Профиль',
    logout: 'Выйти',
  },
  settings: {
    title: 'Внешний Вид',
    default: 'По умолчанию',
    version: 'Версия',
    position: 'Положение елементов',
    theme: 'Цветовая схема',
    dark: 'Тёмная тема',
    toolbarDense: 'Toolbar dense',
    navbarDense: 'Navbar dense',
    navbarLogo: 'Navbar logo',
    settingsBtn: 'Setting btn',
    footer: 'Footer',
  },
  login: {
    titleIn: 'Форма авторизации',
    titleUn: 'Форма регистрации',
    singIn: 'Войти',
    singUp: 'Зарегистрироваться', // "Зарегистрироваться" - верстка плывет, если не убрать кнопки
    email: 'Эл. почта',
    password: 'Пароль',
    confirm: 'Подтвердить',
  },
  errors: {
    whoops: 'Упс',
    back: 'Вернуться!',
    301: 'Перемещён на постоянной основе',
    401: 'Неавторизовано',
    403: 'Запрещено',
    404: 'Не найден',
    500: 'Внутренняя ошибка сервера',
  },
  ui: {
    switch: 'Сменить язык',
    theme: 'Сменить тему',
    success: 'Успех',
    error: 'Ошибка',
    warning: 'Предупреждение',
    info: 'Информация',
    primary: 'Основной',
    secondary: 'Вторичный',
    default: 'Стандартный',
    accent: 'Акцент',
    firstName: 'Имя',
    lastName: 'Фамилия',
    email: 'Электронная почта',
  },
  table: {
    table_settings: "Настройки таблицы",
    key: "Ключ",
    sorting: "Сортировка",
    ascending: "По возрастанию",
    descending: "По убыванию",
    filter: "Фильтр",
    search: "Поиск",
    all: "Все"
  },
  fields: {
    not_available: "Нет в наличии",
    active_positions: "Активных позиций",
    all_positions: "Всего позиций",
    shoppings: "Заказы",
    result_search: "Результаты поиска",
    from_group:"Сгрупировать",
    expand_cat:"Раскрыть категории",
    search: "Поиск",
    "login": "Логин",
    "name": "Имя",
    "phone": "Телефон",
    "image": "Изображение",
    "current_password": "Текущий пароль",
    "new_password": "Новый пароль",
    "confirm_password": "Повторите пароль",
    "name2": "Название",
    "package": "Пакет",
    "billing_end_date": "Действует до",
    "status": "Статус",
    "actions": "Действия",
    "created_at": "Создано",
    "updated_at": "Обновлено",
    "description": "Описание",
    "role": "Роль",
    "deleted": "Удален",
    "sorting": "Сортировка",
    "short_description": "Краткое описание",
    "full_description": "Полное описание",
    "parent": "Родительский уровень",
    "meta_tag": "Мета-тег",
    "icon": "Иконка",
    "language": "Язык",
    "site": "Сайт",
    "code": "Код",
    "filter": "Фильтр",
    "full": "Полное",
    "parameters_seller": "Параметры продавца",
    "parameters_rozetka": "Параметры Розетки",
    "parameter": "Параметр",
    "parameter_name": "Название параметра",
    "price_promo": "Акционная цена",
    "template": "Шаблон",
    "price": "Цена",
    "availability": "Наличие",
    "price_opt": "Оптовая цена",
    "price_purchase": "Закупочная",
    "unit": "Единица измерения",
    "volume": "Объем",
    "is_weight": "Весовой товар",
    "is_sna": "Учет по серийным номерам",
    "country": "Страна",
    "amount": "Количество",
    "amount_products": "Количество товаров",
    "field": "Поле",
    "value": "Значение",
    "retail": "Розничная",
    "old": "Старая",
    "wholesale": "Оптовая",
    "purchasing": "Закупочная",
    "answer": "Ответ",
    "weight": "Вес",
    "price_old": "Старая цена",
    "Product_Code_SKU": "Код товара / Артикул",
    "recommended": "Рекомендованный",
    "vendor": "Производитель",
    "provider": "Поставщик",
    "barcodes": "Штрих-коды",
    "photo": "фото",
    "article": "Статья",
    "size": "Размер",
    "page": "Страница",
    "goods": "Товаров",
    "columns": "Столбцов",
    "delivery_date": "Дата доставки",
    "total": "Сумма",
    "order_number": "Номер заказа",
    "full_name": "ФИО",
    "time_from": "Время от",
    "sum": "Сума",
    "total2": "Всего",
    "delivery_price": "Цена доставки",
    "delivery": "Доставка",
    "payment": "Оплата",
    "discount": "Скидка",
    "address": "Адрес",
    "city": "Город",
    "group": "Группа",
    "comment": "Комментарий",
    "type": "Тип",
    "employee": "Сотрудник",
    "owner": "Владелец",
    "robot": "Робот",
    "position": "Должность",
    "date_of_birth": "Дата рождения",
    "additional_information": "Дополнительная информация",
    "default": "По умолчанию",
    "from_amount_free": "От суммы бесплатно",
    "subject": "Тема",
    "custom_domain": "Собственный домен",
    "tagline": "Слоган",
    "logo": "Логотип",
    "site_alias": "Алиас сайта",
    "default_position_image": "Изображение позиции по умолчанию",
    "background_color": "Цвет фона",
    "site_language": "Выберите язык",
    "background_image": "Фоновое изображение",
    "avatar": "Аватар",
    "timezone": "Часовой пояс",
    "gender": "Пол",
    "to": "до",
    "special": "Специальный",
    "name_of_shop": "Название сайта",
    "new_contact": "Новый контакт",
    "comment_to_client": "Комментарий клиенту (Показывается только клиенту)",
    "admin_comment": "Комментарий администратора (Незаметный клиенту)",
    "domain": "Домен",
    "exchangeRate": "Курс",
    "symbol_left": "Символ слева",
    "symbol_right": "Символ справа",
    "penny": "Копейки",
    "short_t": "Краткое",
    "accumulated_limit": "Накопленный лимит",
    "category": "Категория",
    "default_language": "Язык по умолчанию",
    "new": "Новинка",
    "product": "Товар",
    "company_name": "Название компании",
    "public_index": "Включить индексацию сайта",
    "support_email": "Email поддержки",
    "host": "Хост",
    "port": "Порт",
    "encryption": "Шифрование",
    "export_type": "Тип экспорта",
    "file_name": "Имя файла",
    "file_description": "Описание файла"
  },
  btns: {
    save: "Сохранить",
    delete: "Удалить",
    change_password: "Изменить пароль",
    cancel: "Отмена",
    new: "Новая",
    edit: "Редактировать",
    proceed: "Продолжить",
    close: "Закрыть",
    clear: "Очистить",
    show_details: "Показать детали",
    hide_details: "Скрыть детали",
    add_product: "Добавить товар",
    new_category: "Новая категория",
  }
};
