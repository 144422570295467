<template>
  <div class="d-flex flex-column btn-moderation">
    <v-btn
        class="btn"
        x-small
        color="blue"
        dark
        @click="$emit('send', { key: $event, action: 'btnModeration', moderation: 'validate'})"
    >Валидировать
    </v-btn>
    <v-btn
        class="btn"
        x-small
        color="red"
        dark
        @click="$emit('send', { key: $event, action: 'btnModeration', moderation: 'revision'})"
    >На доработку
    </v-btn>
    <v-btn
        class="btn"
        x-small
        color="orange"
        dark
        @click="$emit('send', { key: $event, action: 'btnModeration', moderation: 'fix'})"
    >Исправить
    </v-btn>
    <v-btn
        class="btn"
        x-small
        color="green"
        dark
        @click="$emit('send', { key: $event, action: 'btnModeration', moderation: 'accept'})"
    >Принять изменения
    </v-btn>

  </div>
</template>

<script>
export default {
  name: "btnModeration"
}
</script>

<style scoped lang="scss">
.btn{
  margin: 5px 15px;
}
</style>