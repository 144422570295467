import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import syncStorage from './plugins/syncStorage';
import permission from './modules/permission';
import settings from './modules/settings';
import user from './modules/user';
import products from './modules/products';
import categories from './modules/categories';
import helper from './modules/helper';
import categoryParams from './modules/categoryParams';

Vue.use(Vuex);

axios.defaults.baseURL = 'https://api.stage.tatet.dev/v1/';
axios.defaults.headers = {
  "Authorization": "Bearer 6297f32dca19ff9448952640a4fc5a069ed9c2ac",
  "accept-company": "f77e91c0-5c83-43d8-8eed-781c83fa148f",
}
export default new Vuex.Store({
  modules: {
    permission,
    settings,
    user,
    products,
    categories,
    helper,
    categoryParams,
  },

  plugins: [
    syncStorage({}),
  ],

  mutations: {},
  actions: {},
  getters: {},
});
