<template>
  <div class="fl-pag" ref="pagEl">
    <div v-if="!limtCols" class="fl-select-box">
      <p >Кол-во строк на странице:</p>
      <v-select
                class="select-box pt-3"
                :items="countList"
                :value="pramsPag.size"
                @change="cntOnPage"
                dense
      ></v-select>
    </div>
    <div v-if="!limtCols" class="fl-info">
      <p>
        {{ info }}
      </p>
    </div>
    <div class="fl-btn-box">
      <!--      First-->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="isFirst" @click="firstPage" large icon v-bind="attrs" v-on="on">
            <v-icon>mdi-page-first</v-icon>
          </v-btn>
        </template>
        <span>Перейти на первую страницу</span>
      </v-tooltip>
      <!--      Previous-->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="isFirst" @click="subPage" large icon v-bind="attrs" v-on="on">

            <svg style="width:32px;height:32px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"/>
            </svg>

          </v-btn>
        </template>
        <span>Перейти на следующую страницу</span>
      </v-tooltip>
      <!--      Next-->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="isLast" @click="addPage" large icon v-bind="attrs" v-on="on">

            <svg style="width:32px;height:32px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/>
            </svg>

          </v-btn>
        </template>
        <span>Перейти на предыдущую страницу</span>
      </v-tooltip>
      <!--      Last-->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="isLast" @click="lastPage" large icon v-bind="attrs" v-on="on">
            <v-icon>mdi-page-last</v-icon>
          </v-btn>
        </template>
        <span>Перейти на последнюю страницу</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>


export default {
  name: "customPagination",
  props: {
    page: {
      type: Number,
      default: () => 1,
    },
    countOnPage: {
      type: Number,
      default: () => 1,
    },
    countList: {
      type: Array,
      default: () => [5, 25, 50, 100]
    },
    items: {
      type: Array,
      default: () => []
    },
    typePaginateServer: {
      type: Boolean,
      default: () => false
    },
    pramsPag: {
      type: Object,
      default: () => ({size: 0, totalElements: 0, totalPages: 0})
    },
    limtCols: {
      type: Number,
      default: null
    },
  },
  watch: {
    page: {
      handler: "listen",
      deep: true,
      immediate: true
    },
    // countOnPage: {
    //   handler: "listen",
    //   deep: true,
    //   immediate: true
    // },
    // items: {
    //   handler: "listen",
    //   deep: true,
    //   immediate: true
    // },

  },
  // created() {
  //   if (this.page >= 1) {
  //     this.Page = this.page
  //   } else {
  //     this.Page = 1
  //   }
  //   if (this.countOnPage >= 5) {
  //     this.countItemOnPage = this.countOnPage
  //   } else {
  //     this.countItemOnPage = 20
  //   }
  //   this.emitVal()
  // },
  data() {
    return {
      Page: null,
      countItemOnPage: 0,
      delay: 200,
      clicks: 0,
      timer: null,
      testT: Function,
    }
  },
  computed: {
    serverCountOnPage() {
      try {
        return this.pramsPag.size
      } catch (e) {
        return 0
      }
    },
    isLast() {
      try {
        if (this.typePaginateServer) return this.Page >= this.pramsPag.totalPages
      } catch (e) {
        return this.Page >= Math.ceil(this.countAll / this.countItemOnPage)
      }
      return this.Page >= Math.ceil(this.countAll / this.countItemOnPage)
    },
    isFirst() {
      return this.Page === 1
    },
    countAll() {
      try {
        if (this.typePaginateServer) return this.pramsPag.totalElements
      } catch (e) {
        return this.items.length
      }
      return this.items.length
    },
    currentPageStart() {
      return (this.Page - 1) * this.countItemOnPage
    },
    currentPageEnd() {
      let currentPageEnd = (this.Page - 1) * this.countItemOnPage + this.countItemOnPage
      if (currentPageEnd > this.countAll) {
        currentPageEnd = this.countAll
      }
      return currentPageEnd
    },
    info() {
      try {
        if (this.typePaginateServer) return `${this.Page} из ${this.pramsPag.totalPages}`
      } catch (e) {
        return `${this.currentPageStart + 1} - ${this.currentPageEnd} из ${this.countAll}`
      }
      return `${this.currentPageStart + 1} - ${this.currentPageEnd} из ${this.countAll}`
    },
    currentChunk() {
      return this.items.slice(this.currentPageStart, this.currentPageEnd)
    },
  },
  methods: {
    listen() {
      if (this.page >= 1) {
        this.Page = this.page
      } else {
        this.Page = 1
      }
      if (this.countOnPage >= 5) {
        this.countItemOnPage = this.countOnPage
      } else {
        this.countItemOnPage = 20
      }

    },
    emitVal() {
      // console.log('emit', this.currentChunk)
      // console.log('emit',this.currentChunk.length )
      if (this.typePaginateServer) {
        let data = {
          'page': this.Page,
          'per-page': this.countItemOnPage
        }
        this.$emit("onPageChange", data)
        return
      }
      this.$emit("onPageChange", this.currentChunk)
      // this.$root.$emit("onPageChangePagination", this.currentChunk)
      // this.$parent.$options.methods.onPageChange(this.currentChunk)
      // this.$root.$emit("onPageChange", this.currentChunk)
      // console.log(this.send)
      // console.log(this.$parent)
    },
    addPage() {
      this.Page++
      this.emitVal()
    },
    subPage() {
      this.Page--
      this.emitVal()
    },
    firstPage() {
      this.Page = 1
      this.emitVal()
    },
    lastPage() {
      try {
        if (this.typePaginateServer) {
          this.Page = this.pramsPag.totalPages
          this.emitVal()
          return
        }
      } catch (e) {
        this.Page = Math.ceil(this.countAll / this.countItemOnPage)
        this.emitVal()
      }
      this.Page = Math.ceil(this.countAll / this.countItemOnPage)
      this.emitVal()
    },
    cntOnPage(e) {
      this.countItemOnPage = e
      this.Page = 1
      this.emitVal()
    }
  }
}
</script>

<style lang="scss" scoped>
.fl-pag {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.fl-info {
  margin-left: 20px;
  padding-top: 10px;
  color: rgba(0, 0, 0, .54);
  font-size: 13px;
}

.fl-select-box {
  display: flex;

  p {
    padding-top: 10px;
    color: rgba(0, 0, 0, .54);
    font-size: 13px;
  }

  .select-box {
    max-height: 30px;
    margin-top: -5px;
    margin-left: 10px;
    width: 70px;
  }


}

.fl-btn-box {
  margin-left: 20px;
}

@media (max-width: 500px) {
  p {
    display: none;
  }
}

</style>
