import axios from 'axios';

const categories = {
    state: {
        categories: [],
        current_category: {},
        result_search: [],
        rozetka_categories: [],
        vendor: [],
    },
    mutations: {
        SET_CATS(state, cat) {
            state.categories = cat;
        },
        SET_CATS2(state, cat) {
            state.categories.items.push(...cat);
        },
        SET_CURRENT_CAT(state, cat) {
            state.current_category = cat
        },
        SET_PARAMS_CATS(state, value) {
            state.params_cats = value
        },
        SET_VENDOR(state, value) {
            state.vendor = value;
        },
    },
    actions: {
        async initVendor({commit}) {
            let params = {
                'per-page': 9999,
            }
            const response = await axios.get('manufacturer', {params: params})
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_VENDOR', response.data);
            } else {
                commit('SET_VENDOR', []);
            }
        },
        async initCat({commit}, params) {
            commit("SET_LOADING", true)
            const response = await axios.get('category', {
                params: params
            })
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_CATS', response.data);
            } else {
                commit('SET_CATS', []);
            }
            commit("SET_LOADING", false)
        },
        async initCat2({commit}, params) {
            commit("SET_LOADING", true)
            const response = await axios.get('category', {
                params: params
            })
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_CATS2', response.data.items);
            } else {
                commit('SET_CATS2', []);
            }
            commit("SET_LOADING", false)
        },
        async currentCategory({commit}, id) {
            const category = await axios.get(`category/${id}`, {params: {'expand': 'params, paramsLinks'}})
                .then(response => response)
                .catch(err => err);
            if (category) {
                commit('SET_CURRENT_CAT', category.data);
            } else {
                commit('SET_CURRENT_CAT', {});
            }
            commit("SET_LOADING", false)
        },
        async initRozetkaCats({commit}, params) {
            const response = await axios.get('collection-rozetka-categories',
                {params: params
        })
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_ROZETKA_CATS', response.data);
            } else {
                commit('SET_ROZETKA_CATS', []);
            }
        },
        async saveCategory({state, commit}, data) {
            const response = await axios.post(`category`, {...data})
                .then(response => response)
                .catch(err => err);
            return response.data
        },
        async updateCategory({state, commit}, data) {
            const response = await axios.put(`category/${data.id}`, {...data})
                .then(response => response)
                .catch(err => err);
            console.log(response)
        },
        async deleteCategory({state, commit}, id) {
            commit("SET_LOADING", true)
            const response = await axios.delete(`category/${id}`)
                .then(response => response)
                .catch(err => err);
            commit("SET_LOADING", false)
            return response.data
        },
    },
    getters: {
        getCats: ({categories}) => categories,
        getCurrentCategory: ({current_category}) => current_category,
        getRozetkaCats: ({rozetka_categories}) => rozetka_categories,
        getVendor: ({vendor}) => vendor
    }

};
export default categories;

