<template>
  <div>
    <v-navigation-drawer
        class="d-lg-block"
        dark
        fixed
        app
        :width="navbarShow ? 280 : 55"
        @input="stateNavbarShow"
    >
      <v-app-bar
          v-if="navbarLogo"
          :dense="toolbarDense"
          dark
          class="my"
      >
        <v-toolbar-title class="text-center">
          <v-avatar
              v-if="navbarShow"
              tile
              size="220"
          >
            <img
                src="img/logo-svg.svg"
                alt="rozetka"
            >
          </v-avatar>
          <v-avatar
              v-if="!navbarShow"
              tile
              size="32"
          >
            <img
                src="img/rozetka_logo.png"
                alt="rozetka"
            >
          </v-avatar>
          <!--          <span v-if="navbarShow">{{ $t('toolbar.appname') }}</span>-->
        </v-toolbar-title>
      </v-app-bar>
      <the-layout-drawer-list
          :dense="navbarDense"
          :routes="permissionRoutes"
          icon-show
      />
    </v-navigation-drawer>
    <v-navigation-drawer
        v-if="width > 1264 ? false : true"
        dark
        fixed
        app
        :value="navbarShow"
        width="250"
        @input="stateNavbarShow"
    >
      <v-app-bar
          v-if="navbarLogo"
          :dense="toolbarDense"
          dark
          class="my"
      >
        <v-toolbar-title class="text-center">
          <v-avatar
              size="32px"
              tile
          >
            <img
                src="img/rozetka_logo.png"
                alt="Rozetka"
            >
          </v-avatar>
          <span v-if="navbarShow">{{ $t('toolbar.appname') }}</span>
        </v-toolbar-title>
      </v-app-bar>
      <the-layout-drawer-list
          :dense="navbarDense"
          :routes="permissionRoutes"
          icon-show
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TheLayoutDrawerList from './TheLayoutDrawerList.vue';

export default {
  name: 'TheLayoutDrawer',
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({
    width: 1264,
  }),
  computed: {
    ...mapGetters([
      'permissionRoutes',
      'navbarDense',
      'navbarShow',
      'navbarLogo',
      'toolbarDense',
    ]),
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    stateNavbarShow(state) {
      this.$store.dispatch('NavbarState', {state});
    },
  },
};
</script>

<style lang="scss">
.my > .v-toolbar__content {
  padding: 4px 10px 4px 15px !important;
}

</style>
