<template>
  <div class="pl-4 dt-td" functional>
    <router-link :to="it.link" v-for="(it, i) in item" :key="i">{{it.name}}</router-link>
  </div>
</template>

<script>
export default {
  props: ["item", "headerItem"],

};
</script>