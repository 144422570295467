<template>
  <div>
    <h3 v-if="title">{{ title }}:</h3>
    <table class="tab">
      <thead>
      <tr class="tr-h">
        <th class="td-f tr-h">Параметр</th>
        <th>Значение</th>
      </tr>
      </thead>
      <tbody>
      <tr class="tr-h" v-for="item in getItems" :key="item.name">
        <td class="td-f">{{ item.param }}</td>
        <td class="td-f2">
          <v-autocomplete v-if="true"
              chips
              deletable-chips
              small-chips
              dense
              :multiple="Array.isArray(item.value)"
              :items=getItemsV
              :value=item.value
              item-text="name"
              item-value="id"
              @change="$emit('send', { key: $event, action: 'rozetkaParams', paramid: item.paramid})"
          ></v-autocomplete>
          <v-text-field v-if="false"
                        @change="$emit('send', { key: $event, action: 'rozetkaParams', paramid: item.paramid})"
          ></v-text-field>
          <v-textarea v-if="false"
                      rows="1"
                      @change="$emit('send', { key: $event, action: 'rozetkaParams', paramid: item.paramid})"
          ></v-textarea>
        </td>
      </tr>
      </tbody>
    </table>
    <v-btn outlined class="btn-all" v-if="checkCount" text x-small @click="checker = !checker">
      <span v-if="!checker">Показать еще...</span>
      <span v-else>Скрыть...</span>
    </v-btn>
  </div>
</template>

<script>

export default {
  props: ["items", "title"],
  data() {
    return {
      checker: false,
      count: 6,
      finArr: [],
    }
  },
  computed: {
    checkCount() {
      return this.items.length > this.count;

    },
    getItems() {
      if (this.items.length < this.count) {
        return this.items
      }

      if (this.checker) {
        return this.items
      } else {
        return this.items.slice(0, this.count)
      }
    },
    getParams: {
      get() {
        return this.item.value
      },
      set(e) {
        this.$emit('send', e)
        console.log(e)
      }
    },
    getItemsV() {
      const v = this.$store.getters['concatParams/getValues']
      return v
    }
  },
  created() {
    this.$store.dispatch('concatParams/actionParams')

  }
};
</script>
<style lang="scss" scoped>

.v-text-field {
  padding-top: 1px !important;
  margin-top: 1px !important;
}

.tr-h {
  font-family: Roboto, sans-serif;
  font-size: 13px;

  & > th {
    border-top: 1px solid #efefee;
    border-bottom: 1px solid #efefee;
    padding: 0 10px;
    text-align: left;
    color: rgba(0, 0, 0, .6);
  }

  & > td {
    padding: 0 10px;
    border-bottom: 1px solid #efefee;
  }
}

.td-f {
  border-right: 1px solid #efefee;
}
.td-f2{
  width: 100%;
}
.btn-all {
  :hover {
    color: #3596f3;
  }
}

h3 {
  padding-left: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, .7);
}
</style>
