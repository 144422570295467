<template>
  <div class="pl-4 bolean dt-td list-m" functional>
    <v-menu offset-y bottom left>
      <template v-slot:activator="{ on }">
        <v-btn class="btn-actions" x-small icon v-on="on">
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="authMarket">
          <v-list-item-title>Авторизация</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item :to="`/${localUrl2}/${item}/goMarketRozetka`">-->
        <!--          <v-list-item-title>Логин Rozetka</v-list-item-title>-->
        <!--        </v-list-item>-->
        <!--        <v-divider></v-divider>-->
        <!--        <v-list-item :to="`/${localUrl2}/${item}/goMarketAdmin?test=1`">-->
        <!--          <v-list-item-title>Авторизация т 2</v-list-item-title>-->
        <!--        </v-list-item>-->
        <!--        <v-list-item :to="`/${localUrl}2/${item}/goMarketAdmin?test=3`">-->
        <!--          <v-list-item-title>Авторизация т 3</v-list-item-title>-->
        <!--        </v-list-item>-->
        <!--        <v-list-item :to="`/${localUrl2}/${item}/goMarketAdmin?test=4`">-->
        <!--          <v-list-item-title>Авторизация т 4</v-list-item-title>-->
        <!--        </v-list-item>-->
        <!--        <v-list-item :to="`/${localUrl2}/${item}/goMarketRozetka?test=4`">-->
        <!--          <v-list-item-title>Авторизация т 4 ROZETKA</v-list-item-title>-->
        <!--        </v-list-item>-->
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      localUrl: 'catalog',
      localUrl2: 'markets',
    }
  },
  methods: {
    ...mapActions("ctest", ["initCat"]),
    authMarket() {
      this.$store.commit("ctest/SET_CATS", [])
      this.initCat({id: this.item, fn: this.respError2})
      this.$router.push(`/${this.item}/${this.localUrl}/products`).catch(() => {
      })
    },
    toDate(val) {
      return new Date(val);
    },

  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-list {
    padding: 0 0;
  }
}
</style>
