import axios from "axios";

const helper = {
    state: {
        statusList: [{
            id: 1,
            text: 'Есть в наличии'
        },
            {
                id: 2,
                text: 'Нет в наличии'
            },
            {
                id: 3,
                text: 'Ожидается'
            },
            {
                id: 4,
                text: 'Снят с производства'
            },
            {
                id: 5,
                text: 'На заказ'
            },
            {
                id: 6,
                text: 'Зарезервировано'
            },
            {
                id: 7,
                text: 'Заканчивается'
            },
            {
                id: 8,
                text: 'Уточняйте наличие'
            },
            {
                id: 9,
                text: '---'
            },
            {
                id: 10,
                text: 'На складе'
            },
        ]
    },
    mutations: {},
    actions: {},
    getters: {}

};
export default helper;
