<template>
  <div class="pl-4 bolean dt-td" functional>
    <a class="href" target="_blank" :href="item">{{item}}</a>
  </div>
</template>

<script>
export default {
  props: ["item"]
};
</script>
<style scoped>
.href {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.href:hover {
  color: #0056b3;
  text-decoration: underline;
}
</style>
