<template>

  <div
      class="data-table elevation-5"
      v-resize="setWidth"
      ref="dataTable"
      :class="['data-' + tabId, { loading: loading }]"
      :style="{ height: height }"
  >
    <div
        class="dt-header"
        ref="header"
        :style="{ minWidth: allWidth + 'px' }"
        @mouseup="thMouseup"
    >
      <div
          class="table-wrap-checkbox"
          v-if="selectable && headersTable.length > 1"
          @click="selectAll"
      >
        <v-checkbox
            class="table-mini-checkbox"
            dense
            hide-details
            :indeterminate="indeterminate"
            :value="selections.length === items.length && selections.length"
        ></v-checkbox>
      </div>
      <div
          class="td-th  d-flex justify-space-between"
          :class="{ editable: item.edit }"
          :style="{
          width: widthCols[item.value] + 'px',
          minWidth: widthCols[item.value] + 'px',
        }"
          v-for="(item, i) in headersTable"
          :key="i + item.value"
          :data-id="item.value"
          :track-by="i"
          @mousedown="thMousedown($event, item)"
          @mousemove="thMousemove"
      >
        <template>
          <span class="th-text" :title="item.text">{{ item.text }}</span>
          <v-menu
              offset-y
              right
              allow-overflow
              :close-on-content-click="false"
              v-if="item.sortable || item.search !== false"
              max-width="220"
          >
            <template v-slot:activator="{ on }">
              <div class="dt-search-icon">
                <v-btn
                    x-small
                    class="ml-0"
                    v-on="on"
                    @click="setSearch(item)"
                    icon
                >
                  <v-icon class="hide-icon" small>mdi-dots-vertical</v-icon>
                </v-btn>
              </div>
            </template>
            <div class="bg-white">
              <div class="py-3">
                <div class="caption px-3 mb-2 text--secondary">
                  {{ $t("table.sorting") }}
                </div>
                <div class="pop-list">
                  <div
                      class="d-flex item caption align-center px-3 py-1"
                      :class="{ active: sort.dir === 'asc' }"
                      @click="setSort(item.value, 'asc')"
                  >
                    <v-icon
                        small
                        class="mr-2"
                        v-if="sort.dir === 'asc'"
                        color="green"
                    >mdi-check
                    </v-icon
                    >
                    <div class="pl-6" v-else></div>
                    <div class="text">
                      {{ $t("table.ascending") }} ( <b>А</b>-Я)
                    </div>
                  </div>
                  <div
                      class="d-flex item caption align-center px-3 py-1"
                      :class="{ active: sort.dir === 'desc' }"
                      @click="setSort(item.value, 'desc')"
                  >
                    <v-icon
                        small
                        class="mr-2"
                        v-if="sort.dir === 'desc'"
                        color="green"
                    >mdi-check
                    </v-icon
                    >
                    <div class="pl-6" v-else></div>
                    <div class="text">
                      {{ $t("table.descending") }} ( <b>Я</b>-А)
                    </div>
                  </div>
                </div>
              </div>
              <v-divider v-if="item.search !== false"></v-divider>
              <div class="dt-small-input pa-3" v-if="item.search !== false">
                <div class="caption mb-1 text--secondary">
                  {{ $t("table.filter") }}
                </div>
                <div>
                  <v-text-field
                      :placeholder="$t('table.search')"
                      v-model="search.input"
                      dense
                      single-line
                      hide-details
                      autofocus
                  ></v-text-field>
                </div>
              </div>

              <v-divider v-if="item.template === 'select'"></v-divider>
              <div
                  class="dt-small-input pa-3"
                  v-if="item.template === 'select'"
              >
                <div class="caption mb-1 text--secondary">
                  {{ $t("table.filter") }}
                </div>
                <v-select
                    dense
                    class="small-select"
                    v-model="item.select"
                    solo
                    :items="[{ id: 0, name: $t('table.all') }, ...item.list]"
                    item-text="name"
                    item-value="id"
                    hide-details
                    @change="setSelect(item, $event)"
                ></v-select>
              </div>
            </div>
          </v-menu>

          <div class="drag"></div>
        </template>
      </div>
      <div
          class="td-th caption last-td-th"
          @mousedown="thMousedown($event, {})"
          @mousemove="thMousemove"
      ></div>

      <v-btn
          class="header-setting-btn"
          x-small
          @click.prevent="$refs.settings.dialog = true"
          icon
      >
        <v-icon class="hide-icon">mdi-table-edit</v-icon>
      </v-btn>
    </div>

    <div class="wrap-td-boby wr-sc" @scroll="eScroll" ref="wrapTdBoby">
      <div class="td-boby" :style="{ minWidth: allWidth + 'px' }">
        <slot name="preBody"></slot>
        <td-row
            v-for="(item, index) in dataTree"
            :index="index"
            :level="0"
            :fields="fields"
            :headers="headersTable"
            :key="'row' + item[keyRow]"
            :data="item"
            :widthCols="widthCols"
            :allWidth="allWidth"
            :send="send"
            :activeID="activeID"
            :editField="editField"
            :setEditField="setEditField"
            :openMap="openMap"
            :setOpen="setOpen"
            :actions="actions"
            :selectable="selectable"
            :selections="selections"
            :keyRow="keyRow"
            :menu="showMenu"
            :options="options"
            :show-expand="showExpand"
            :expand-rows="expandRows"
            :active-expand-rows="activeExpandRows"
        ></td-row>
      </div>
    </div>

    <settings ref="settings" :headers="tempHeaders"/>

    <v-menu
        v-model="menu.show"
        v-if="actions && actions.length"
        :position-x="menu.x"
        :position-y="menu.y"
        absolute
        offset-y
        max-width="250"
    >
      <v-list dense class="contextmenu" v-if="menu.show">
        <v-list-item>
          <v-list-item-content v-if="menu.activeEl.title || menu.activeEl.name">
            <v-list-item-title>
              <b>{{
                  menu.activeEl.title ? menu.activeEl.title : menu.activeEl.name
                }}</b>
              <!-- <small>
                <b>({{menu.activeEl.id}})</b>
              </small>-->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="menu.activeEl.title || menu.activeEl.name"></v-divider>
        <v-list-item-group color="primary">
          <div v-for="(action, i) in actions" :key="i">
            <v-list-item
                @click="action.func(menu.activeEl)"
                v-if="accessMenu(menu.activeEl, action)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ action.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <template v-if="showPagin">
      <custom-pagination
          ref="custom"
          :count-on-page="20"
          :items="getBuildArr"
          :page="page"
          :type-paginate-server="typePaginateServer"
          class="pag"
          :limt-cols="limtCols"
          :prams-pag="pramsPag"
          @onPageChange="onPageChange"
      />
    </template>


  </div>
</template>

<script>
import tdRow from "./tdRow";
import arrayToTree from "array-to-tree";
import sortByOrder from "lodash.sortbyorder";
import props from "./props";
import settings from "./settings";
import cloneDeep from "clone-deep";
import customPagination from "./customPagination";

function paddingDiff(col) {
  if (getStyleVal(col, "box-sizing") == "border-box") {
    return 0;
  }

  var padLeft = getStyleVal(col, "padding-left");
  var padRight = getStyleVal(col, "padding-right");
  return parseInt(padLeft) + parseInt(padRight);
}

function getStyleVal(elm, css) {
  return window.getComputedStyle(elm, null).getPropertyValue(css);
}

export default {
  components: {
    tdRow,
    settings,
    customPagination,
  },
  props,
  data() {
    return {
      settingDialog: false,
      menu: {
        show: false,
        activeEl: {},
        x: null,
        y: null,
      },
      selections: [],
      tabWidth: 600,
      activeID: null,
      search: {
        input: "",
        field: null,
      },
      editField: {
        id: null,
        field: null,
      },
      widthCols: {},
      sort: {
        by: "id",
        dir: "asc",
      },
      pageX: undefined,
      curCol: undefined,
      //   nxtCol: undefined,
      curColWidth: undefined,
      // nxtColWidth: undefined,
      openMap: {},
      tempHeaders: [],
      pause: false,
      arrView: [],
    };
  },
  created() {
    if (this.map) this.openMap = this.getState();
    if (this.activeId) this.activeID = this.activeId;
    if (this.sortTable) this.sort = {...this.sortTable};
    this.headers.forEach((o) => {
      if (o.active !== false) {
        this.$set(o, "active", true);
      }
    });
    let headers = this.getState("headers");
    if (!Array.isArray(headers)) headers = this.headers;
    this.tempHeaders = cloneDeep(headers);
  },
  mounted() {
    // this.$root.$on('onPageChangePagination', this.onPageChange1)
    setTimeout(() => {
      this.setWidth();
      // this.buildArr();
    }, 30);
    document.addEventListener("click", this.blurInput);
    // console.log(this.$scopedSlots);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.blurInput);
  },
  watch: {
    headers() {
      this.setWidth();
      this.selections = this.selected ? this.selected : [];
    },
    items() {
      this.selections = this.selected ? this.selected : [];
      // this.buildArr();
    },
    activeId(val) {
      //    console.log(val);
      if (this.activeId) {
        this.activeID = this.activeId;
        // console.log(this.activeId);
        return;
      }
      if (val) this.activeID = val;
    },
    selections() {
      this.$emit("selections", this.selections);
      // console.log(this.selections)
    },
    search: {
      deep: true,
      handler() {
        this.$emit("eSearch", this.search);
      },
    },
  },
  methods: {
    buildArr() {
      let arr = sortByOrder(this.items, this.sort.by, this.sort.dir);
      //  console.log(arr);
      if (this.search.input && this.search.field) {
        arr = arr.filter((o) => {
          let text = o[this.search.field] + "";
          return text.toLowerCase().includes(this.search.input.toLowerCase());
        });
      }
      // console.log(arr)
      if (this.parent) {
        return arrayToTree(arr, {
          parentProperty: this.parent,
          customID: this.keyRow,
        });
      }
      return arr
    },
    onPageChange(e) {
      if (this.typePaginateServer) {
        this.$emit('paginate', e)
        return
      }
      this.arrView = [...e]
    },
    deepCopy(val) {
      return JSON.parse(JSON.stringify(val))
    },
    setSearch(item) {
      if (this.search.field != item.value) {
        this.search.input = "";
        this.search.field = item.value;
      }
    },
    clearWidth() {
      this.$refs.header.style.left = 0;
    },
    setSelect(item, e) {
      // console.log(item, e);
      if (!e) {
        this.search.field = null;
        this.search.input = "";
        return;
      }
      this.search.field = item.value;
      this.search.input = e + "";
      //  this.$emit("eSearch", item.value);
    },
    accessMenu(item, action) {
      if (action.access) {
        return action.access(item);
      }
      return true;
    },
    showMenu(e, data) {
      e.preventDefault();

      //  console.log(e, data);
      // return;
      this.menu.show = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.$nextTick(() => {
        this.menu.show = true;
      });
      this.menu.activeEl = data;
    },
    eScroll(e) {
      let p = e.target.scrollLeft ? -e.target.scrollLeft : 0;
      this.$refs.header.style.transform = `translate(${p}px)`;
      let contentHeight = e.target.scrollHeight; // 1) высота блока контента вместе с границами
      let y = e.target.scrollTop + e.target.offsetHeight;

      if (this.pause) return;
      // если пользователь достиг конца
      if (y + 300 >= contentHeight) {
        this.$emit("eScroll", "next");
      }
      this.pause = true;
      setTimeout(() => {
        this.pause = false;
      }, 300);
    },
    selectAll(e) {
      if (this.selections.length) {
        this.selections = [];
      } else {
        this.selections = this.items.map((o) => o.id);
      }
      this.$emit("select", this.selections);
    },
    setSort(item, dir = null) {
      this.sort.by = item;
      if (!dir) {
        this.sort.dir = this.sort.dir === "asc" ? "desc" : "asc";
      } else {
        this.sort.dir = dir;
      }
      this.$emit("eSort", this.sort);
    },
    setEditField(id = null, field = null, data) {
      // console.log(data);
      this.editField = {[this.keyRow]: id, field};
      if (data) {
        this.$emit("saveRow", data);
        // console.log(data);
      }
    },
    thMousedown(e, item) {
      //   console.log(e, item);
      this.curCol = e.target.parentElement;

      // this.nxtCol = this.curCol.nextElementSibling;
      this.pageX = e.pageX;

      // console.log(this.pageX);

      let padding = paddingDiff(this.curCol);
      // console.log(this.curCol.offsetWidth);

      this.curColWidth = this.curCol.offsetWidth - padding;
      //  if (this.nxtCol) this.nxtColWidth = this.nxtCol.offsetWidth - padding;
    },
    thMouseup() {
      //  console.log("thMouseup");
      this.curCol = undefined;
      // this.nxtCol = undefined;
      this.pageX = undefined;
      //  this.nxtColWidth = undefined;
      this.curColWidth = undefined;
    },
    thMousemove(e) {
      e.preventDefault();
      if (this.curCol) {
        let diffX = e.pageX - this.pageX;
        //  console.log(diffX);
        //   return;
        if (this.nxtCol) {
          //   this.widthCols[this.nxtCol.dataset.id] = this.nxtColWidth - diffX;
        }
        // console.log(this.curCol.dataset.id, this.curColWidth + diffX);
        let w = this.curColWidth + diffX;
        if (w < 68) return;
        this.widthCols[this.curCol.dataset.id] = w;
      } else {
        //console.log(0);
      }
    },
    send(data, action = "") {
      if (action === "select") {
        //  console.log(data, "select");
        if (!this.selections.includes(data.id)) {
          this.selections.push(data.id);
        } else {
          let temp = [...this.selections];
          temp = temp.filter((v) => v != data.id);
          this.selections = temp;
        }
        this.$emit("select", this.selections);
        return;
      }
      if (data.action === "toggleExpand") {
        this.$emit("toggleExpand", data.data)
      }
      this.$emit("send", data);
      this.activeID = this.activeId ? this.activeId : data.id;
      // console.log(this.activeID = this.activeId ? this.activeId : data.id)
      // setTimeout(() => {
      //   // this.setWidth();
      // }, 2500);
    },
    setWidth() {
      if (!this.$refs.dataTable) return;
      this.tabWidth = this.$refs.dataTable.clientWidth;
      let data = {};
      let summ = 0;
      let count = 0;
      this.tempHeaders.forEach((o) => {
        if (o.width) summ = summ + o.width;
        else count++;
      });
      //console.log(summ, this.tabWidth, count);
      let oneW = (this.tabWidth - summ) / count;

      this.tempHeaders.forEach((o) => {
        if (o.width) data[o.value] = o.width;
        else data[o.value] = oneW;
      });

      this.widthCols = data;
    },
    setOpen(id, data) {
      let temp = {...this.openMap};
      if (temp["id" + id]) {
        temp["id" + id] = false;
      } else {
        temp["id" + id] = true;
      }
      this.openMap = temp;
      this.setState(temp);
      this.$emit("openFolder", data);
    },
    setState(data, key = "openMap") {
      if (!this.tabId) return;
      data = JSON.stringify(data);
      if (!data) data = {};
      localStorage.setItem("table_" + this.tabId + "_" + key, data);
    },
    getState(key = "openMap") {
      if (!this.tabId) false;
      let data = localStorage.getItem("table_" + this.tabId + "_" + key);
      if (!data) return {};
      // console.log(JSON.parse(data))
      return JSON.parse(data);
    },
    removeState(key = "openMap") {
      localStorage.removeItem("table_" + this.tabId + "_" + key);
      if (key === "headers") {
        this.tempHeaders = cloneDeep(this.headers);
      }
    },
    blurInput(e) {
      if (this.editField.id && e.target.tagName !== "INPUT") {
        this.setEditField();
      }
    },
  },
  computed: {
    getBuildArr() {
      let arr = sortByOrder(this.items, this.sort.by, this.sort.dir);
      //  console.log(arr);
      if (this.search.input && this.search.field) {
        arr = arr.filter((o) => {
          let text = o[this.search.field] + "";
          return text.toLowerCase().includes(this.search.input.toLowerCase());
        });
      }
      // console.log(arr)
      if (this.parent) {
        return arrayToTree(arr, {
          parentProperty: this.parent,
          customID: this.keyRow,
        });
      }
      return arr
    },
    cActions() {
      //    if(item.)
      return [];
    },
    headersTable() {
      let headers = this.tempHeaders.filter((o) => o.active !== false);
      let limtCols = this.limtCols;
      if (this.headers.length === 2 && !limtCols) limtCols = 2;
      if (limtCols) {
        return headers.slice(0, limtCols);
      } else {
        return headers;
      }
    },
    options() {
      return {
        defaultIcon: this.defaultIcon,
        isfolder: this.isfolder,
      };
    },
    indeterminate() {
      let res =
          this.selections.length && this.selections.length < this.items.length;
      return res ? true : false;
    },
    allWidth() {
      let all = 0;
      // let headers = this.headers;
      //
      // for (let i in this.widthCols) {
      //   let f = this.tempHeaders.find(o => o.value === i);
      //   if (f && f.active) {
      //     all = +this.widthCols[i] + +all;
      //   }
      //   //  console.log(this.widthCols[i], all);
      // }
      // console.log(this.tempHeaders);

      this.headersTable.forEach((o) => {
        all = +o.width + +all;
      });
      return all;
    },
    fields() {
      return this.tempHeaders.map((o) => o.value);
    },
    dataTree() {
      // console.log('fin', this.showPagin, this.arrView.length, this.arr.length, this.getBuildArr.length)
      if (this.typePaginateServer) return this.getBuildArr;
      return this.showPagin ? this.arrView : this.getBuildArr;
    },
  },
};
</script>

<style lang="scss">
@import "./style";

.pag {
  position: sticky;
  bottom: 0;
  right: 0;
  background-color: #fafafa;
}
</style>
