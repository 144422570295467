import Layout from '@/views/layout/TheLayout.vue';

const officeRouter = {
    path: '/office',
    component: Layout,
    name: 'Office',
    redirect: 'noredirect',
    meta: {
        title: 'route.office',
        icon: 'mdi-account-tie-outline',
    },
    children: [
    ],
};

export default officeRouter;
