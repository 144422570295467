<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<style src="./styles/style.scss" lang="scss"></style>

<script>

export default {
  name: 'App',
};
</script>
