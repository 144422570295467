import axios from "axios";

const categories = {
    state: {
        params_cats: [],
    },
    mutations: {
        SET_PARAMS_CATS(state, value) {
            state.params_cats = value
        },
    },
    actions: {
        async initParamsCats({commit}, id = null) {
            let params = {
                expand: "values",
                'per-page': 9999,
                'filter[id]': id
            }
            const response = await axios.get('params', {params: params})
                .then(response => response)
                .catch(err => err);
            if (response) {
                commit('SET_PARAMS_CATS', response.data);
            } else {
                commit('SET_PARAMS_CATS', []);
            }
            commit("SET_LOADING", false)
        },
        async addParamsCats({commit}, data) {
            const response = await axios.post('params/create', {...data})
                .then(response => response)
                .catch(err => err);
            return response.data
        },
        async deleteParamsCats({commit}, id) {
            const response = await axios.delete(`params/delete-cat/${id}`)
                .then(response => response)
                .catch(err => err);
            return response.data
        },
    },
    getters: {
        getParamsCats: ({params_cats}) => params_cats,
    }

};
export default categories;

