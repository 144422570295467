<template>
  <div class="pl-4 bolean dt-td" functional>
    <v-icon v-if="item" small color="green">mdi-check</v-icon>
    <v-icon v-else small color="red">mdi-close</v-icon>
  </div>
</template>

<script>
export default {
  props: ["item"]
};
</script>