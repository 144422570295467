<template>
  <div class="edit-name">
    <v-text-field
        hide-details
        class="ma-0 pa-0 td-boby ml-3"
        v-model="name"
        prepend-icon="mdi-pencil"
        color="blue"
        :append-outer-icon="change ? 'mdi-send' : ''"
        :append-icon="change ? 'mdi-restore' : ''"
        @click:append="reset"
        @click:append-outer="sendMessage"
        @input="change = true"
    ></v-text-field>
  </div>

</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      change: false,
      name: '',
      marker: true,
      iconIndex: 0,
    }
  },
  mounted() {
    this.name = this.item
  },
  watch: {
    item() {
      this.name = this.item
    }
  },
  methods: {
    reset() {
      this.change = false
      console.log("reset")
      this.name = this.item
    },
    sendMessage() {
      this.change = false
      console.log(this.name)
    },

  },
}
</script>

<style lang="scss">

.edit-name {
  .v-input__icon--prepend i {
    color: #07b4f8 !important;
    font-size: 18px !important;
  }

  .v-input__icon--append > .v-icon {
    color: #ffcf6e !important;
  }

  .v-input__icon--append > .primary--text {
    color: #00bb00 !important;
    caret-color: #00bb00 !important;
  }
}
</style>