<template>
  <div>
    <h3 v-if="title">{{ title }}:</h3>
    <table class="tab">
      <thead>
      <tr class="tr-h">
        <th class="td-f tr-h">Параметр</th>
        <th>Значение</th>
      </tr>
      </thead>
      <tbody>
      <tr class="tr-h" v-for="item in getItems" :key="item.name">
        <td class="td-f">{{ item.param }}</td>
        <td>{{ item.value }}</td>
      </tr>
      </tbody>
    </table>
    <v-btn class="btn-all" v-if="checkCount" text x-small @click="checker = !checker">
      <span v-if="!checker">Показать еще...</span>
      <span v-else>Скрыть...</span>
    </v-btn>
  </div>
</template>

<script>

export default {
  props: ["items", "title"],
  data() {
    return {
      checker: false,
      count: 6
    }
  },
  computed: {
    checkCount() {
      return this.items.length > this.count;

    },
    getItems() {
      if (this.items.length < this.count) {
        return this.items
      }

      if (this.checker) {
        return this.items
      } else {
        return this.items.slice(0, this.count)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.tab {
}

.tr-h {
  font-family: Roboto, sans-serif;
  font-size: 13px;

  & > th {
    border-top: 1px solid #efefee;
    border-bottom: 1px solid #efefee;
    padding: 2px 10px;
    text-align: left;
    color: rgba(0, 0, 0, .6);
  }

  & > td {
    padding: 2px 10px;
    border-bottom: 1px solid #efefee;
  }
}

.td-f {
  border-right: 1px solid #efefee;
}

.btn-all {
  :hover {
    color: #3596f3;
  }
}

h3 {
  padding-left: 10px;
  font-size: 14px;
}
</style>
