var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pagEl",staticClass:"fl-pag"},[(!_vm.limtCols)?_c('div',{staticClass:"fl-select-box"},[_c('p',[_vm._v("Кол-во строк на странице:")]),_c('v-select',{staticClass:"select-box pt-3",attrs:{"items":_vm.countList,"value":_vm.pramsPag.size,"dense":""},on:{"change":_vm.cntOnPage}})],1):_vm._e(),(!_vm.limtCols)?_c('div',{staticClass:"fl-info"},[_c('p',[_vm._v(" "+_vm._s(_vm.info)+" ")])]):_vm._e(),_c('div',{staticClass:"fl-btn-box"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isFirst,"large":"","icon":""},on:{"click":_vm.firstPage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-page-first")])],1)]}}])},[_c('span',[_vm._v("Перейти на первую страницу")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isFirst,"large":"","icon":""},on:{"click":_vm.subPage}},'v-btn',attrs,false),on),[_c('svg',{staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"}})])])]}}])},[_c('span',[_vm._v("Перейти на следующую страницу")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isLast,"large":"","icon":""},on:{"click":_vm.addPage}},'v-btn',attrs,false),on),[_c('svg',{staticStyle:{"width":"32px","height":"32px"},attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"}})])])]}}])},[_c('span',[_vm._v("Перейти на предыдущую страницу")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isLast,"large":"","icon":""},on:{"click":_vm.lastPage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-page-last")])],1)]}}])},[_c('span',[_vm._v("Перейти на последнюю страницу")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }