<template>
  <div class="dt-row">
    <div
        class="d-flex dt-row-inner"
        :style="{ minWidth: allWidth + 'px' }"
        :class="{
        'active-row': activeID && activeID == data[keyRow],
        selected: selections.includes(data[keyRow]),
      }"
        @contextmenu="menu($event, data)"
    >
      <div
          class="table-wrap-checkbox d-flex align-center"
          v-if="selectable && headers.length > 1"
      >
<!--        <v-checkbox-->
<!--            class="table-mini-checkbox"-->
<!--            color="#2196F3"-->
<!--            dense-->
<!--            hide-details-->
<!--            style="color: #2196F3"-->
<!--            :value="selections.includes(data[keyRow])"-->
<!--            @change="send(data, 'select')"-->
<!--            :has-input="true"-->
<!--        ></v-checkbox>-->


         <label class="pl-1 pr-3 custom-checkbox">
          <input
            type="checkbox"
            :checked="selections.includes(data.id)"
            @change="send(data, 'select')"
          />
          <span></span>
        </label>
      </div>
      <div
          v-for="(item, key) in rowHeaders"
          :key="item + key"
          class="dt-cell"
          :style="{
          width: widthCols[item.value] + 'px',
          minWidth: widthCols[item.value] + 'px',
        }"
      >
        <div v-if="item.template">
          <div
              v-if="item.template === 'name'"
              class="d-flex align-center cursor-pointer cat-title"
              :class="padLeft"
          >
            <div class="ml-3">
              <v-icon
                  small
                  class="mr-2"
                  color="success"
                  @click="setOpen(data[keyRow], data)"
                  v-if="data.children || data.hasChild"
              >{{
                  openMap["id" + data[keyRow]]
                      ? "mdi-folder-open"
                      : "mdi-folder"
                }}
              </v-icon>
              <v-icon
                  @click="preSend(data, $event)"
                  small
                  class="mr-2"
                  color="success"
                  v-else-if="options.isfolder"
              >mdi-grain
              </v-icon
              >
              <v-icon
                  small
                  class="mr-2"
                  color="green"
                  @click="preSend(data, $event)"
                  v-else
              >{{ data.icon ? data.icon : options.defaultIcon }}
              </v-icon
              >
            </div>
            <input
                type="text"
                v-if="
                editField.field === item.value &&
                  editField[keyRow] == data[keyRow]
              "
                class="dt-input"
                :class="'input-' + item.value + index"
                @keyup.enter="save(data, item, $event)"
                @blur="save(data, item, $event)"
                v-model="data[item.value]"
            />
            <div
                @dblclick="setEdit(item, data, index)"
                @click="preSend(data, $event)"
                class="dt-td"
                v-else
            >
              <span :title="data[item.value]">{{
                  data[item.value] ? data[item.value] : data[item.value2]
                }}</span>
            </div>
            <v-icon
                v-if="data.mark_icon"
                small
                class="mr-2"
                style="opacity: 1"
                color="green"
                :title="data.mark_text"
            >{{ "mdi-" + data.mark_icon }}
            </v-icon
            >
          </div>
          <!-- <div v-if="item.template === 'switch'" class="pl-3 switch">
            <v-switch
              v-model="data[item.value]"
              @change="save(data, item.value, $event)"
              dense
              hide-details
            ></v-switch>
          </div>-->

          <!-- <bolean v-if="item.template === 'bolean'" :item="data[item.value]" /> -->
          <div v-if="item.template === 'bolean'" class="pl-4 bolean dt-td">
            <v-icon
                small
                :style="item.edit === true && 'cursor:pointer'"
                :title="item.edit === true && 'Чтоб изменить дважди кликните'"
                @dblclick="save(data, item, $event)"
                :color="data[item.value] ? 'green' : 'red'"
            >mdi-{{ data[item.value] ? "check" : "close" }}
            </v-icon
            >
          </div>

          <cellPath
              v-if="item.template === 'path'"
              :item="data[item.value]"
              :headerItem="item"
          />
          <cellParamName
              v-if="item.template === 'paramName'"
              :data="data"
              :item="data[item.value]"
              :meta="item.meta"
              @send="preSend"
          />
          <cellImageValidationControl
              v-if="item.template === 'imageValidationControl'"
              :item="data"
              @send="preSend"
          />
          <cellImageValidationField
              v-if="item.template === 'imageValidationField'"
              :item="data"
              @send="preSend"
          />
          <cellLink
              v-if="item.template === 'link'"
              :item="data[item.value]"
          />
          <cellSimpleLink
              v-if="item.template === 'simpleLink'"
              :item="data[item.value]"
          />
          <cellEmail
              v-if="item.template === 'email'"
              :item="data[item.value]"
          />
          <cellDate
              v-if="item.template === 'dateStr'"
              :item="data[item.value]"
          />
          <cellAuthMarket
              v-if="item.template === 'authMarket'"
              :item="data[item.value]"
          />

          <params
              :items="data[item.value]"
              :title="item.title"
              v-if="item.template === 'params'"
          />
          <image-pl
              :items="data[item.value]"
              v-if="item.template === 'images'"
              :count=item.count
              :width=item.width
          />
          <description
                  :items="data[item.value]"
                  v-if="item.template === 'description'"
                  :title="header.title"
          />
          <param-rozetka
                  :items="data"
                  v-if="item.template === 'paramRozetka'"
                  :title="header.title"
                  @send="preSend"
          />
          <edit-name
                  v-if="item.template === 'editName'"
                  :item="data[item.value]"
          />
          <btn-params-edit
                  v-if="item.template === 'btnParamsEdit'"
                  :item="data"
                  @send="preSend"
          />


          <div v-if="item.template === 'checkbox'" class="dt-td">
            <v-checkbox
                class="table-mini-checkbox"
                color="#2196F3"
                dense
                hide-details
                style="color: #2196F3"
                v-model="data[item.value]"
                @change="item.func(data, item.value)"
            ></v-checkbox>
          </div>

          <cellSelect
              v-if="item.template === 'select'"
              :item="data[item.value]"
              :obj="data"
              :headerItem="item"
          />
          <div v-if="item.template === 'date'" class="dt-td">
            {{ toDate(data[item.value]) }}
          </div>

          <div
              v-if="item.template === 'actions' && actions && !data.hideActions"
              class="dt-td"
          >
            <v-menu offset-y bottom left>
              <template v-slot:activator="{ on }">
                <v-btn class="btn-actions" x-small icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <div v-for="(action, i) in actions" :key="i">
                  <v-list-item
                      @click="action.func(data)"
                      v-if="accessMenu(data, action)"
                  >
                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div v-else>
          <input
              type="text"
              v-if="
              editField.field === item.value &&
                editField[keyRow] == data[keyRow]
            "
              class="dt-input"
              :class="'input-' + item.value + index"
              @keyup.enter="save(data, item, $event)"
              @blur="save(data, item, $event)"
              v-model="data[item.value]"
          />
          <div @dblclick="setEdit(item, data, index)" class="dt-td" v-else>
            {{ data[item.value] }}
          </div>
        </div>

      </div>
      <div v-if="expandRows.length"
           class="dt-cell"
      >
<!--        <v-tooltip top>-->
          <!--          <template v-slot:activator="{ on, attrs }">
          v-bind="attrs" v-on="on"
          -->
          <v-btn small color="success" icon>
            <v-icon v-if="activeExpandRows.includes(data.id)" @click="HideExpand">mdi-chevron-up
            </v-icon>
            <v-icon v-else @click="ShowExpand">mdi-chevron-down</v-icon>
          </v-btn>
          <!--          </template>-->
          <!--          <span v-if="activeExpandRows.includes(data.id)">Скрыть дополнительную информацию</span>-->
          <!--          <span v-else>Показать дополнительную информацию</span>-->
<!--        </v-tooltip>-->
      </div>
    </div>
    <div
        :key="'id' + data[keyRow]"
        v-if="data.children && openMap['id' + data[keyRow]]"
    >
      <td-row
          v-for="(data2, index) in data.children"
          :index="index"
          :level="level + 1"
          :fields="fields"
          :key="'trItem' + data2[keyRow]"
          :data="data2"
          :headers="headers"
          :widthCols="widthCols"
          :activeID="activeID"
          :allWidth="allWidth"
          :send="send"
          :editField="editField"
          :setEditField="setEditField"
          :openMap="openMap"
          :setOpen="setOpen"
          :actions="actions"
          :selectable="selectable"
          :selections="selections"
          :keyRow="keyRow"
          :menu="menu"
          :options="options"
      ></td-row>
    </div>
    <v-expand-transition>
      <expand-temp :item="data" :expand-rows="expandRows"
                   v-if="activeExpandRows.includes(data.id) && expandRows.length"/>
    </v-expand-transition>
  </div>
</template>

<script>
import tdRow from "./tdRow";
import expandTemp from "./expandTemp";
import bolean from "./templates/bolean";
import cellPath from "./templates/path";
import cellSelect from "./templates/select";
import cellLink from "./templates/link";
import cellSimpleLink from "./templates/simpleLink";
import cellEmail from "./templates/email";
import cellDate from "./templates/dateStr";
import cellAuthMarket from "./templates/authMarket";
import cellImageValidationControl from './templates/imageValidationControl';
import cellImageValidationField from './templates/imageValidationField';
import cellParamName from './templates/paramsLink';
import params from './templates/params'
import imagePl from './templates/img'
import cloneDeep from "clone-deep";
import description from './templates/description'
import paramRozetka from "./templates/paramRozetka";
import editName from "./templates/editName";
import btnParamsEdit from "./templates/btnParamsEdit";

export default {
  name: "tdRow",
  watch: {
    showExpand: {
      handler: "initExpand",
    }
  },
  mounted() {
    // console.log(this.showExpand, this.expandRows.length)

  },
  components: {
    tdRow,
    bolean,
    cellPath,
    cellLink,
    cellSimpleLink,
    cellEmail,
    cellDate,
    cellAuthMarket,
    cellSelect,
    cellImageValidationControl,
    cellImageValidationField,
    cellParamName,
    expandTemp,
    params,
    imagePl,
    description,
    paramRozetka,
    editName,
    btnParamsEdit
  },
  data() {
    return {
      edit: false,
      delay: 200,
      clicks: 0,
      timer: null,
      before: {},
      showEx: false
    };
  },
  methods: {
    initExpand() {
      this.showEx = this.deepCopy(this.showExpand)
    },
    deepCopy(val) {
      return JSON.parse(JSON.stringify(val))
    },
    accessMenu(item, action) {
      if (action.access) {
        return action.access(item);
      }
      return true;
    },
    save(data, hItem, e) {
      //  console.log(data, hItem);
      if (!hItem.edit) return;
      if (hItem.template === "bolean") {
        data[hItem.value] = !data[hItem.value];
      }
      let item = {...data};

      if (data.event) return;
      if (item[hItem.value] === this.before[hItem.value]) return;
      if (e.code === "Enter") {
        data.event = "Enter";
        //   return;
      }
      // if (e) {
      //   // console.log(e);
      //   item[key] = e.target.checked;
      // }
      this.setEditField(null, null, item);
      setTimeout(() => {
        data.event = null;
      }, 500);
    },
    setEdit(field, data, index) {
      if (!field.edit) return;
      //console.log(this.editField);

      this.before = {...data};
      //return
      this.edit = true;
      this.setEditField(data[this.keyRow], field.value);
      setTimeout(() => {
        document.querySelector(".input-" + field.value + index).focus();
      }, 10);
    },
    preSend(data, e) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
          this.send(data);
        }, this.delay);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
      }
    },
    ShowExpand() {
      const arr = cloneDeep(this.activeExpandRows)
      arr.push(this.data.id)
      this.preSend({action: "toggleExpand", data: arr})
    },
    HideExpand() {
      let arr = cloneDeep(this.activeExpandRows)
      arr = arr.filter(val => val !== this.data.id)
      this.preSend({action: "toggleExpand", data: arr})
    }
  },
  computed: {
    padLeft() {
      if (this.level) {
        let t = this.level * 2;
        return "pl-" + (t + 1);
      }
    },
    rowHeaders() {
      // console.log(this.data, this.headers);
      if (this.data.onecell) {
        return this.headers.filter((o) => o.value === this.data.onecell);
      }
      return this.headers;
    },
    // openMap() {
    //   return this.$store.state.categories.openMap;
    // }
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    keyRow: {
      type: String,
      default: "id",
    },
    itemMenu: {
      required: false,
      default: null,
    },
    headers: {
      type: Array,
      required: true,
    },
    selections: {
      type: Array,
      default: () => ([]),
    },
    actions: {
      type: Array,
      default: null,
    },
    openMap: {
      type: Object,
    },
    setOpen: {
      type: Function,
    },
    editField: {
      type: Object,
    },
    index: {
      type: Number,
    },
    send: {
      type: Function,
    },
    menu: {
      type: Function,
    },
    setEditField: {
      type: Function,
    },
    data: {
      type: Object,
      required: true,
    },
    widthCols: {
      type: Object,
      required: true,
    },
    activeID: {
      default: null,
    },
    allWidth: {
      type: Number,
      default: null,
    },
    level: {
      type: Number,
      default: 0,
    },
    fields: {
      type: Array,
      required: false,
      default: () => (["name", "id", "shopId", "parent"]),
    },
    selectable: {
      style: String,
      default: null,
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    expandRows: {
      type: Array,
      default: () => ([])
    },
    activeExpandRows: {
      type: Array,
      default: () => ([])
    },
  },
};
</script>
<style scoped>
</style>
